import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import selectNavBarData from '../redux/selectors';

function NavBar() {
    const { isLoggedIn, user } = useSelector(selectNavBarData);

    return (
        <nav className="navbar navbar-dark ng-gradient navbar-expand-lg navbar-light bg-dark" id="mainNav">
            <div className="container px-4 px-lg-5">
                <Link className="navbar-brand" to="/">
                    HelB
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto py-4 py-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link px-lg-3 py-3 py-lg-4" to="/">
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-lg-3 py-3 py-lg-4" to="/dictionary">
                                English Dictionary
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-lg-3 py-3 py-lg-4" to="/about">
                                About
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-lg-3 py-3 py-lg-4" to="/contact">
                                Contact
                            </Link>
                        </li>
                    </ul>
                    {isLoggedIn ? (
                        <div className="dropdown">
                            <button
                                className="btn btn-primary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {user ? user.username : 'User'}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <Link className="dropdown-item" to="/profile">
                                        Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/logout">
                                        Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <Link className="btn btn-primary me-2" to="/login/">
                            Login
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
